import Error from "next/error";

// Components
import PageWrapper from "../components/PageWrapper";

export default function Custom404() {
  return (
    <PageWrapper>
      <Error statusCode={404} title="Page Not Found" />
    </PageWrapper>
  );
}
